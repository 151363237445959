@use './src/mediaStyles';

.infoWindow {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-width: 230px;
	.mealPreviewList > * {
		border-bottom: 1px solid var(--secondary-color);
		&:last-child {
			border: none;
		}
	}
	.mealPlaceName {
		max-width: 230px;
	}
}
.searchHereBtn {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 65px;
	background-color: var(--main-color);
	box-shadow: rgba(0, 0, 0, 0.2) 2px 1px 13px 0px;
}

.versionWrapper {
	position: absolute;
	bottom: 15px;
	width: 100%;
	display: flex;
	justify-content: center;
	.versionNumber {
		font-size: 14px;
		color: white;
		font-weight: bold;
		text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7);
		// box-shadow: 0px 1px 5px 0px rgba(50, 50, 50, 0.4);
	}
}

@media (max-width: mediaStyles.$breakpoint-mobile) {
	.versionWrapper {
		.versionNumber {
			display: none;
		}
	}
}
