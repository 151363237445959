@use './src/mediaStyles';

.root {
	max-width: 600px;
	margin: auto;
	margin-top: 30px;
	min-height: 100px;
	position: relative;
	.sectionTitle {
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size: 17px;
		font-weight: bold;
		margin-right: 5px;
		& > * {
			margin: 0;
			margin-left: 5px;
		}
		& *:first-child {
			margin-top: 2px;
		}
	}
	.mealsList {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
}

@media (max-width: mediaStyles.$breakpoint-mobile) {
	.root {
		padding: 0 15px;
	}
}
