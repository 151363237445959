.formContent {
	height: calc(100% - 60px);
	overflow-y: auto;
	overflow-x: hidden;
	h5{
		font-size: 14px;
		margin-bottom: 3px;
		padding-right: 0;
	}
}
.meatTypeRadio {
	display: flex !important;
	flex-direction: row !important;
	align-items: center;
	justify-content: space-between;
	min-width: 200px;
	width: 230px;
}
