@use './src/mediaStyles';

.root {
	max-width: 600px;
	margin: auto;
	margin-top: 30px;
	max-height: 900px;
	overflow-y: auto;
	position: relative;
	.sectionTitle {
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size: 17px;
		font-weight: bold;
		& > * {
			margin: 0;
			margin-left: 5px;
		}
		& *:first-child {
			margin-top: 5px;
		}
	}
	.rateMealInput {
		height: 35px;
		padding: 0 10px;
		border-radius: 3px;
		margin: 15px 0;
		text-align: right;
		display: flex;
		justify-content: right;
		align-items: center;
		border: solid 1px #ccd3e1;
		color: var(--steel-color);
		font-size: 13px;
		letter-spacing: 0.5px;
		&:hover {
			cursor: text;
		}
	}
}
@media (max-width: mediaStyles.$breakpoint-mobile) {
	.root {
		padding: 0 15px;
	}
}
