button {
	display: inline-block;
	border: none;
	text-decoration: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}
.mainBtnContainer {
	padding: 10px 20px;
	margin: 5px;
	border-radius: 3px;
	background-color: var(--main-color);
	transition: box-shadow 0.2s ease-out;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	font-weight: 600;
	font-family: 'Assistant', sans-serif;
	&:hover {
		cursor: pointer;
		box-shadow: 0 2px 6px 0 #00000025;
	}
	p {
		margin: 0;
	}
}
.disabled-true {
	opacity: 0.3;
	background-color: black;
	color: white;
	&:hover {
		cursor: default;
		background-color: black;
	}
}

.loading-true {
	background-color: var(--secondary-color);
	&:hover {
		cursor: not-allowed;
		box-shadow: none;
	}
}
