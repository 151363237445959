@use './src/mediaStyles';

.formWrapper {
	text-align: right;
	width: 620px;
	position: relative;
	height: 500px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 5px;
	&:hover {
		.ScrollbarsCustom-TrackY {
			opacity: 1;
		}
	}
}

@media (max-width: mediaStyles.$breakpoint-mobile) {
	.formWrapper {
		width: 100%;
		height: 100%;
		padding: 0;
		justify-content: space-around;
	}
}
