@use '../../../colors';

.searchContainer {
	position: relative;
}

.searchBox {
	background: none;
	border: none;
	border-bottom: 1.5px solid #979797;
	font-family: Assistant, sans-serif;
	color: 'colors.$gunmetal-color';
	direction: rtl;
	width: 97%;
	font-size: 14px;
	position: relative;
	// &::-moz-placeholder {
	// 	color: #ccd3e1;
	// 	opacity: 0.8;
	// }
	&:focus {
		border-color: var(--main-color);
		outline: none;
	}
	&:placeholder-shown {
		padding-right: 0%;
		& ~ .cancelBtn {
			visibility: hidden;
		}
		& + .searchIcon {
			visibility: visible;
		}
	}
}

.cancelBtn {
	position: absolute;
	font-size: 12px;
	left: 6px;
	top: 3px;
	&:hover {
		cursor: pointer;
	}
}

.searchIcon {
	position: absolute;
	visibility: hidden;
	font-size: 12px;
	left: 3%;
	top: 5px;
}
