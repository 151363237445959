.root {
	width: calc(100% + 20px);
	background-color: black;
	position: relative;
	right: -8px;
	border-radius: 3px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.backgroundImage {
		position: absolute;
		top: 30px;
		width: 98%;
		left: 0;
	}
	.callToAction {
		font-size: 26px;
		color: white;
		font-weight: normal;
		position: absolute;
		bottom: 40px;
		right: 22px;
		margin: 0;
	}
	.ctaDescription {
		color: var(--main-color);
		font-size: 16px;
		font-weight: normal;
		position: absolute;
		bottom: 20px;
		margin: 0;
		right: 22px;
	}
	.logoutBtn {
		margin-top: 5px;
		margin-bottom: 0px;
	}
}
