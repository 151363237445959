@use './src/mediaStyles';

.root {
	width: 590px;
	height: 160px;
	box-shadow: 0 1px 4px 0 #0000001a;
	background-color: var(--background-color);
	display: flex;
	width: 97%;
	margin: 10px auto;
	border-radius: 3px;
	position: relative;
	.paddingWrapper {
		align-items: center;
		padding: 0 15px;
		width: 100%;
		&:hover {
			cursor: pointer;
		}
	}
	.details {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: calc(100% - 25px);
		line-height: 15px;
		letter-spacing: 0.5px;
		text-align: right;
		margin-right: 10px;
		.title {
			font-weight: bold;
			font-size: 16px;
			margin: 0;
		}
		.place {
			margin-top: 3px;
			margin-bottom: 5px;
			font-size: 13px;
		}
		.chipWrapper {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			color: #52555c;
			font-size: 13px;
			letter-spacing: 0.5px;
			.partChip {
				background-color: #ffefd3;
				padding: 2px 4px;
				margin-left: 3px;
				margin-top: 3px;
				border-radius: 3px;
			}
			.extraChips {
				margin-left: 3px;
				margin-top: 3px;
				padding: 2px 4px;
			}
		}
		.matchPrecent {
			position: absolute;
			right: 11px;
			top: 7px;
			background-color: var(--main-color);
			padding: 3px 5px;
			border-radius: 3px;
			font-size: 13px;
		}
	}
}
.noReviews {
	display: flex;
	flex-direction: row;
	align-items: center;
	color: var(--gunmetal-color);
	font-size: 13px;
	letter-spacing: 0;
}
.imageCarousel {
	width: 170px;
	height: 130px;
	border-radius: 3px;
	background: black;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.favBadge {
	background-color: black;
	color: var(--main-color);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 44px;
	height: 44px;
	border-radius: 50%;
	border: 3px solid #fffbf4;
	position: absolute;
	top: 7px;
	left: 6px;
	pointer-events: none;
}

@media (max-width: mediaStyles.$breakpoint-mobile) {
	.root {
		width: calc(100% - 30px);
		height: 340px;
		align-items: flex-start;
		.paddingWrapper {
			padding: 0 0 15px 0;
			align-items: unset;
		}
		.details {
			height: auto;
			margin-top: 10px;
			.noReviews {
				margin-top: 8px;
			}
			.chipWrapper {
				min-height: 40px;
				align-items: center;
				.partChip {
					max-height: 17px;
				}
			}
		}
		.reviewSection {
			display: flex;
			flex-direction: row;
			align-items: center;
			& > * {
				margin-left: 3px;
			}
			margin: 7px 0 8px 0;

			.starsWrapper {
				width: 65px;
				margin-top: 2px;
			}
			.scoreCount {
				color: var(--steel-color);
			}
			.score {
				font-weight: bold;
			}
		}
	}
	.imageCarousel {
		width: 100%;
		margin: auto;
		height: 180px;
	}
}
