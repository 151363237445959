@use './src/mediaStyles';

.root {
	width: 600px;
	height: 400px;
	display: flex;
	flex-direction: column;
	position: relative;
	.filtersWrapper {
		height: calc(100% - 100px);
		overflow: auto;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: right;
		position: relative;
		.filterItem {
			width: 33%;
			min-height: 30px;
			overflow: hidden;
		}
	}
	.filterSearchInput {
		width: calc(100% - 20px);
	}
	.sendBtn {
		position: absolute;
		bottom: 20px;
		width: 100%;
		margin: auto;
	}
}
@media (max-width: mediaStyles.$breakpoint-mobile) {
	.root {
		width: 100%;
		height: 100%;
		min-width: unset;
		.filtersWrapper {
			min-width: unset;
			.filterItem {
				width: 50%;
			}
		}
	}
}
