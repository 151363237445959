@use './src/mediaStyles';
.root {
	width: 100%;
	height: 100%;
	position: relative;
	background-color: black;
	.image {
		width: 100%;
		height: 100%;
	}
	.rightArrow,
	.leftArrow {
		position: absolute;
		background-color: white;
		width: 30px;
		height: 30px;
		color: black;
		border-radius: 50%;
		transition: all 0.2s ease-in-out;
		z-index: 10;
		display: flex;
		justify-content: center;
		font-size: 18px;
		font-weight: bold;
		align-items: center;
		opacity: 0.4;
		&:hover {
			cursor: pointer;
			opacity: 1;
		}
	}
	.rightArrow {
		right: 5%;
		top: 50%;
	}
	.leftArrow {
		left: 5%;
		top: 50%;
	}
	.imgCount {
		position: absolute;
		top: 10px;
		right: 20px;
		font-size: 16px;
		color: gray;
	}
	.imgSource {
		position: absolute;
		left: -5px;
		padding: 3px 10px;
		bottom: 10px;
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 3px;
		color: white;
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		font-size: 15px;
		font-weight: 600;
		display: inline-flex;
		overflow: hidden;
		> * {
			margin-left: 10px;
		}
		.imgSourceLink {
			max-width: 150px;
			overflow: hidden;
			text-overflow: ellipsis;
			text-transform: capitalize;
			white-space: nowrap;
			text-align: left;
			direction: ltr;
		}
	}
}
@media (max-width: mediaStyles.$breakpoint-mobile) {
	.root {
		height: 180px;
		position: relative;
		top: 110px;
	}
}
