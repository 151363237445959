.root {
	margin-left: 5px;
	margin-top: 3px;
	padding: 2px 5px;
	border-radius: 3px;
	letter-spacing: 0.5px;
	font-size: 13px;
	display: flex;
	justify-content: center;
	align-self: center;
	background-color: #2e3035;
	color: #ccd3e1;
	width: fit-content;
}
.red {
	background-color: #ff5757;
	color: white;
}
.green {
	background-color: #5cc471;
	color: white;
}
