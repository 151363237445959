.placeSection {
	display: flex;
	text-align: right;
	flex-direction: column;
	align-items: flex-start;
	margin: 10px auto;
	width: calc(100% - 30px);
	position: relative;
	.placeTitle {
		margin: 20px 0 0 0;
		font-size: 17px;
		font-weight: bold;
		letter-spacing: 0.5px;
	}
	.placeInfo {
		font-family: Assistant;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: 0.4px;
		margin: 0;
		padding-bottom: 10px;
		width: 100%;
		text-align: right;
		border-bottom: 1px solid var(--secondary-color);
	}
	.websiteLink {
		padding-left: 30px;
		max-width: calc(100% - 30px);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: 600;
		direction: ltr;
	}
	.btnText {
		font-size: 14px;
		text-transform: none;
		direction: rtl;
		font-weight: bold;
	}
	.placeMap {
		border-bottom: 1px solid var(--secondary-color);
		position: relative;
		width: 100%;
		margin-top: 8px;
	}
}
