@use '../../../../../colors';

.root {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	justify-content: flex-start;
	margin-top: 10px;
	.names {
		display: flex;
		flex-direction: row;
		align-items: center;
		color: colors.$gunmetal-color;
		font-size: 13px;
		letter-spacing: 0;
		.userName {
			margin: 0 3px;
		}
	}
	.icon {
		position: relative;
		top: 2px;
		right: 3px;
	}
}
