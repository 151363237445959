@use 'colors';
@use 'mediaStyles';
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;600;700;800&display=swap');

html,
body,
#root,
.App {
	margin: 0;
	height: 100%;
	width: 100vw;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	// scrollbar-width: thin;
	scrollbar-color: rgba(0, 0, 0, 0.2) hsla(0, 0%, 100%, 0.1);
}

:root {
	--main-color: #{colors.$main-color};
	--secondary-color: #{colors.$secondary-color};
	--accent-color: #{colors.$accent-color};
	--background-color: #{colors.$background-color};
	--hover-color: #{colors.$hover-color};
	--gunmetal-color: #{colors.$gunmetal-color};
	--steel-color: #7c818b;
	font-family: 'Assistant', sans-serif;
	-ms-overflow-style: none; /* IE and Edge */
}
.pac-container {
	z-index: 2000 !important;
}
.gm-style .gm-style-iw-c {
	max-width: unset !important;
}
.App {
	text-align: center;
	direction: rtl;
	.textLink {
		font-size: 13px;
		font-weight: bold;
		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}
		&:disabled {
			color: white;
			opacity: 0.6;
			font-weight: 600;
		}
	}
}
.smallLink {
	position: relative;
	color: black;
	font-size: 13px;
	font-weight: bold;
	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
}
.errorMsg {
	font-size: 13px;
	color: rgb(214, 8, 8);
}

.mainTextLink {
	color: var(--main-color);
	font-weight: 600;
	text-decoration: underline;
	&:hover {
		cursor: pointer;
	}
}

.arrayListItem {
	&:not(:last-child):after {
		// color: black;
		content: ', ';
	}
}

.loadingOverlay {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-color: white;
	opacity: 0.8;
	z-index: 15;
}

form {
	h2,
	h5 {
		margin: 0;
		padding-top: 0.5em;
		padding-right: 0.3em;
	}
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}
}

a {
	text-decoration: none;
}

ul.smallLinksList > li:hover {
	cursor: pointer;
	font-weight: 600;
}

.ScrollbarsCustom-TrackY {
	opacity: 0;
	width: 6px !important;
	height: 97% !important;
	top: 0;
	left: 2px !important;
	transition: opacity 0.2s ease-in-out;
	z-index: 5;
}
.MuiChip-clickableColorPrimary:hover,
.MuiChip-clickableColorPrimary:focus {
	background-color: var(--main-color) !important;
}
.MuiChip-clickableColorSecondary {
	color: black !important;
	background-color: lightgray !important;
}

@media (max-width: mediaStyles.$breakpoint-mobile) {
	.smallLink {
		text-decoration: underline;
	}
	.scrollBar {
		-webkit-overflow-scrolling: touch;
	}
}

@media (min-width: mediaStyles.$breakpoint-mobile) {
	.scrollBar {
		scrollbar-width: thin;
		::-webkit-scrollbar {
			width: 12px;
		}
		::-webkit-scrollbar-track {
			background-color: hsla(0, 0%, 100%, 0.1);
		}
		::-webkit-scrollbar-thumb {
			border-radius: 5px;
			background-color: rgba(0, 0, 0, 0.2);
		}
	}
}
