@use './src/mediaStyles';

.sideBarContainer {
	border-radius: 3px;
	width: 810px;
	height: calc(100% - 40px);
	font-size: 1rem;
	box-shadow: 2px 1px 13px 0 rgba(0, 0, 0, 0.2);
	z-index: 8;
	position: fixed;
	right: 5px;
	top: 50px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	background-color: var(--background-color);
	padding-top: 5px;
	&:hover {
		.ScrollbarsCustom-TrackY {
			opacity: 1;
		}
	}
	.topRibbons {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		.verticalDivider {
			border: 1px solid #b3b3b3;
			height: 1.3em;
			position: relative;
			top: 0.15em;
		}
		p {
			font-size: 16px;
			color: #666666;
			&:hover {
				cursor: pointer;
			}
		}

		.selected {
			color: black;
			font-weight: bold;
		}
	}
	.ScrollbarsCustom-Wrapper {
		position: unset !important;
		inset: unset !important;
	}
}
@media (max-width: mediaStyles.$breakpoint-mobile) {
	.sideBarContainer {
		border-radius: 0;
		right: 0;
		width: 100%;
		height: 100%;
		box-shadow: none;
		// background-color: unset;
	}
}
