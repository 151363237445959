.root {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-color: var(--background-color);
	opacity: 0.85;
	z-index: 15;
	direction: rtl;
}
