.root {
	display: flex;
	text-align: right;
	margin-bottom: 18px;
	border-bottom: 1px solid var(--secondary-color);
	padding-bottom: 15px;
	.userSection {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		text-align: center;
		flex-wrap: wrap;
		min-width: 130px;
		&:hover {
			cursor: pointer;
		}
		& > * {
			margin-bottom: 8px;
		}
		.userName {
			font-size: 13px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.15;
			letter-spacing: normal;
			margin: 0;
		}
	}
	.reviewSection {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		margin-top: 6px;

		.metaData {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 4px;
		}
		.scoreNumber {
			font-size: 13px;
			font-weight: bold;
			margin-right: 5px;
		}
		.date {
			font-size: 13px;
			font-weight: normal;
			margin-right: 15px;
			color: var(--steel-color);
		}
		.comment {
			font-size: 13px;
			text-align: right;
		}
	}
}
