.root {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}
.title {
	font-size: 13px;
	font-weight: bold;
	letter-spacing: 0.5px;
	margin: 10px 10px 0 0;
}
.checkboxLabel {
	font-size: 13px;
	line-height: 1.9;
	letter-spacing: 0.5px;
	color: #52555c;
}
.extraOptionsLink {
	margin-right: 40px;
	&:hover {
		cursor: pointer;
	}
}
