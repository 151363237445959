.root {
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid;
	text-transform: uppercase;
	position: relative;
	overflow: hidden;
	.nameTag {
		margin-left: 1px;
	}
	.image {
		width: 100%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
}
