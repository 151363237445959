@use './src/mediaStyles';

.root {
	text-transform: capitalize;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 300px;
	min-width: 400px;
	.input {
		display: none;
		border: 0;
		clip: rect(0 0 0 0);
		clip-path: inset(50%);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		white-space: nowrap;
		width: 1px;
	}
	.userName {
		margin-top: 5px;
	}
}
.imageCircle {
	width: 84px;
	height: 84px;
	border-radius: 50%;
	border: 2px solid black;
	position: relative;
	overflow: hidden;
	padding: none;
	background-position: center;
	background-size: cover;
	.imageButton {
		height: 100%;
		width: 100%;
	}
}
.profileImage {
	position: absolute;
	top: 0;
	height: 100%;
}
.updateFields {
	width: 100%;
	& > * {
		margin-bottom: 15px;
	}
	.nameWrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}
.displayMsg-neg,
.displayMsg-pos {
	font-size: 16px;
	letter-spacing: 1.5px;
}
.errMsg {
	color: red;
}
.sucMsg {
	color: green;
}

@media (max-width: mediaStyles.$breakpoint-mobile) {
	.root {
		min-width: unset;
		width: 100%;
	}
}
