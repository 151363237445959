@use './src/colors';
@use './src/mediaStyles';

.root {
	width: 210px;
	display: flex;
	flex-direction: column;
	justify-content: right;
	text-align: right;
	position: relative;
	.filterSection {
		overflow-y: auto;
		overflow-x: hidden;
		text-overflow: ellipsis;
		padding-right: 10px;
		height: 100%;
		.sectionTitle {
			font-size: 13px;
			font-weight: bold;
			letter-spacing: 0.5px;
			margin: 10px 10px 0 0;
		}
		.sliderValue {
			font-size: 13px;
			font-weight: 600;
			line-height: 1.92;
			position: relative;
			right: 10px;
			top: -5px;
			letter-spacing: 0.5px;
			color: colors.$gunmetal-color;
		}
		.geoSelect {
			margin-right: -2px;
			margin-bottom: 10px;
		}
	}
	.clearBtnWrapper {
		position: absolute;
		bottom: 10px;
		display: flex;
		left: 50%;
		.clearBtn {
			font-size: 13px;
			background: white;
			color: black;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: bold;
			box-shadow: 0 2px 4px 0 #00000018;
			width: 145px;
			height: 40px;
			border-radius: 3px;
		}
	}
}
.sectionHeader {
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: 0.6px;
	margin: 10px 15px 15px 0;
}
@media (max-width: mediaStyles.$breakpoint-mobile) {
	.root {
		width: 100%;
		height: calc(100vh - 110px);
		.sectionHeader {
			text-align: center;
			font-weight: bold;
			margin: 10px 0;
			font-size: 21px;
		}
		.closeDrawerBtn {
			position: absolute;
			right: 5px;
			font-weight: 200;
			top: 10px;
		}
		.filterSection {
			margin-top: 10px;
		}
		.clearBtnWrapper {
			bottom: 15px;
			.clearBtn {
				font-size: 14px;
				width: 110px;
				height: 40px;
			}
		}
	}
}
