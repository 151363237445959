.root {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.circle {
		width: 108px;
		height: 108px;
		border: 1px solid var(--secondary-color);
		border-radius: 50%;
		position: absolute;
		margin: auto;
	}
	title {
		font-size: 13px;
		font-weight: bold;
		letter-spacing: 1.5px;
		margin-bottom: 3px;
	}
	.rating {
		font-size: 26px;
		letter-spacing: 0;
		margin-bottom: 3px;
	}
	.reviewCount {
		color: var(--steel-color);
		font-size: 13px;
		margin-top: 3px;
	}
}
