.formInputWrapper {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	width: 100%;
	margin-bottom: 5px;
	position: relative;
	.textLabel {
		margin: 0;
		margin-bottom: 3px;
		font-size: 14px;
		font-weight: bold;
		display: inline;
		:first-child {
			font-weight: normal;
			font-size: 14px;
		}
	}
	.formInput {
		direction: rtl;
		width: 100%;
		background-color: white;
		border-radius: 3px;
		border: solid 1.5px #b1b1b1;
		font-size: 12px;
		padding: 5px 7px;
		&:focus {
			border-color: var(--main-color);
			outline: none;
		}
	}
}
