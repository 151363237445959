@use './src/mediaStyles';

.root {
	position: relative;
	text-align: right;
	transform: translateX(-2px);
}
.headerSection {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	.sortBy {
		letter-spacing: 0.6px;
		margin: 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 110px;
		margin-left: -15px;
		& > :first-child {
			width: 40px;
		}
	}
}
.headerTitle {
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: 0.6px;
	margin: 10px;
	text-align: right;
}
.noResultWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	.noResultsBtns {
		display: flex;
		flex-direction: row;
		.actionBtn {
			font-size: 13px;
			font-weight: bold;
		}
	}
}
@media (max-width: mediaStyles.$breakpoint-mobile) {
	.root {
		padding-bottom: 10px;
	}
	::-webkit-scrollbar {
		width: 0px;
	}
	.headerSection {
		text-align: center;
		justify-content: center;
	}
	.feedList {
		margin-bottom: 10px;
	}
	.infiniteScroll {
		padding-bottom: 65px;
	}
}
