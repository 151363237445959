@use './src/mediaStyles';

.topbarWrapper {
	width: 100vw;
	position: fixed;
	z-index: 12;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	text-align: center;

	.barContainter {
		background-color: black;
		color: white;
		width: 100%;
		height: 50px;
		font-size: 13px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-shadow: 2px 1px 13px 0 rgba(0, 0, 0, 0.2);
		padding: 0 15px;
	}
	.filtersContainer {
		display: flex;
		flex-direction: row;
	}
	.searchContainer {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		& > * {
			margin-left: 20px;
		}
	}
	.logoWrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		&:hover {
			cursor: pointer;
		}
		.logoImage {
			margin: 0;
		}
	}
	.infoIcon {
		position: relative;
		right: -14px;
		top: -2px;
		font-size: 18px;
		transition: color 0.2s ease-in-out;
		&:hover {
			cursor: pointer;
			color: var(--main-color);
		}
	}
	.barsMenu {
		position: relative;
		color: white;
		top: 1%;
		&:hover {
			cursor: pointer;
		}
	}
	label:hover {
		cursor: pointer;
	}
}
.userContainer {
	display: flex;
	justify-content: flex-end;
	.userName {
		color: white;
		font-weight: 400;
		font-size: 13px;
		margin-left: 0.5em;
	}
}

.itemTitle {
	font-weight: 'normal';
	font-size: 26;
	margin: 0;
}
.itemDesc {
	margin-bottom: 5px;
	margin-top: 3px;
	font-size: 16px;
	color: #52555c;
}

.closeButton {
	position: relative;
	top: -8px;
	right: -5px;
	&:hover {
		cursor: pointer;
	}
}

.profileShape {
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-top: 10%;
	align-items: center;
	width: 95%;
	height: 180px;
	margin: -8px auto -5px auto;
	position: relative;
	> h3 {
		color: var(--main-color);
		letter-spacing: 1.5px;
		font-weight: 600;
		margin-top: 2%;
	}
}

.topBarSection {
	color: white;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-weight: 600;
	width: 100%;
	height: 100%;
	.flexGroup {
		display: flex;
		flex-direction: row;
		align-items: center;
		& > * {
			margin-right: 10px;
		}
	}
}
.paddedBtn-left {
	padding-left: 20px;
}
.paddedBtn-right {
	padding-right: 20px;
}
@media (max-width: mediaStyles.$breakpoint-mobile) {
	.topbarWrapper {
		top: 0;
		position: relative;
		z-index: 6;
		.barContainter {
			background-color: black;
			padding: 0 10px 0 15px;
			color: white;
			width: 100%;
			border-radius: 0;
			box-shadow: none;
		}
		.infoIcon {
			right: calc(50vw + 49px);
			top: 6px;
			font-size: 15px;
			padding: 5px;
			position: absolute;
		}
	}
	.userCircle {
		width: 60px;
		height: 60px;
		color: white;
		font-size: 26px;
		background: black;
		z-index: 5;
	}
	.userName {
		margin-left: unset;
		color: white;
		margin-top: 8px;
		font-weight: 600;
		font-size: 16px;
		z-index: 10;
	}
}
