.root {
	min-width: 200px;
	padding: 10px 0;
	font-family: 'Assistant', sans-serif;
	transition: transform 0.15s ease-in-out;
	&:first-of-type {
		padding: 0 0 10px 0;
	}
	&:hover {
		cursor: pointer;
		box-shadow: 0 1px 4px 0 #0000001a;
		transform: translateY(-2px);
	}
	.details {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: start;
		align-items: right;
		font-size: 13px;
		margin-right: 5%;
		& > * {
			margin-top: 4px;
		}
		.reviewSection {
			display: flex;
			flex-direction: row;
			align-items: center;
			& > * {
				margin-left: 3px;
			}
			.starsWrapper {
				width: 65px;
				margin-top: 2px;
			}
			.scoreCount {
				color: var(--steel-color);
			}
			.score {
				font-weight: bold;
			}
		}
		.mealTitle {
			margin: 0;
			line-height: 15px;
			letter-spacing: 0.4px;
			font-weight: bold;
		}
		.placeTitle {
			margin: 0;
			line-height: 15px;
		}
	}
	.imageCarousel {
		max-width: 74px;
		height: 55px;
		border-radius: 3px;
		background: black;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
	}
}
