@use './src/mediaStyles';

.root {
	width: 100%;
	height: calc(100vh - 50px);
	position: relative;
	top: 50px;
	background-color: var(--background-color);
	z-index: 100;
	overflow-y: auto;
	overflow-x: hidden;
	.navBar {
		width: 100%;
		display: flex;
		justify-content: right;
		height: 40px;
		padding-right: 20px;
		box-shadow: 0 1px 0 0 var(--secondary-color);
		margin-bottom: 20px;
		.backBtn {
			font-size: 13px;
			font-weight: 600;
			letter-spacing: 0.5px;
		}
	}
	.contentWrapper {
		width: calc(100% - 40px);
		height: calc(100vh - 160px);
		max-width: 1280px;
		margin: auto;
		padding-bottom: 50px;
		.heroSection {
			display: flex;
			flex-direction: row;
		}
		.imageCarusel {
			width: 100%;
			min-height: 400px;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			position: relative;
			background-color: #fff6e6;
			.img {
				min-height: 400px;
				width: 100%;
				background-size: cover;
				background-color: #fff6e6;
				background-position: center;
				height: 100%;
			}
		}
		.details {
			background-color: black;
			color: white;
			text-align: right;
			padding-right: 20px;
			min-height: 400px;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.title {
				margin-bottom: 0px;
				font-size: 46px;
				font-weight: 600;
				letter-spacing: 2.65px;
				line-height: 40px;
				margin-top: 15px;
			}
			.place {
				padding-top: 5px;
				font-size: 16px;
				letter-spacing: 0.9px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				margin: 0;
				.placeLink {
					text-decoration: underline;
					&:hover {
						cursor: pointer;
					}
				}
			}
			.reviewsCount {
				color: #ccd3e1;
				font-size: 20px;
				margin-top: 2px;
			}
			.rating {
				margin: 6px 0;
				font-size: 30px;
				letter-spacing: 2.9px;
				color: var(--main-color);
				margin-right: 5px;
			}
			.mealInfoWrapper {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 100%;
				position: relative;
			}
			.tagList {
				display: flex;
				flex-direction: row;
				margin-bottom: 15px;
			}
		}
		.actionSection {
			background-color: #fff6e6;
			max-width: 820px;
			height: 60px;
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: flex-start;
			padding: 0 30px;
			margin-top: 30px;
			& > * {
				margin-left: 10px;
			}
			.actionBtn {
				height: unset;
				width: unset;
			}
			.favBtn {
				background: #fffcf3;
				box-shadow: 0 2px 4px 0 #00000018;
			}
			.deleteBtnLabel {
				transition: all 0.1s;
				&:hover {
					transform: scale(1.1);
					transform: translateX(-5px);
				}
			}
		}
		.addImgBtn {
			position: absolute;
			top: 10px;
			left: 10px;
			background: #fffcf3;
			box-shadow: 0 2px 4px 0 #00000018;
			font-weight: 600;
			padding: 5px 12px;
			font-size: 14px;
		}
		.otherPlaceMeals {
			max-width: 600px;
			margin: auto;
			margin-top: 10px;
			position: relative;
		}
	}
	.btnText {
		font-size: 14px;
		text-transform: none;
		direction: rtl;
		font-weight: bold;
	}
	.sectionTitle {
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size: 17px;
		font-weight: bold;
		margin-right: 5px;
		& > * {
			margin: 0;
			margin-left: 5px;
		}
		.titleIcon {
			padding-top: 5px;
		}
	}
	.scrollTopBtn {
		position: fixed;
		left: 20%;
		bottom: 8%;
		min-width: 100px;
		background-color: var(--main-color);
		z-index: 10;
	}
}

@media (max-width: mediaStyles.$breakpoint-mobile) {
	.root {
		top: 0;
		z-index: 100;
		height: calc(100vh - 105px);
		.contentWrapper {
			width: 100vw;
			margin: auto;
			.heroSection {
				flex-direction: column-reverse;
			}
			.imageCarusel {
				min-height: 180px;
				max-height: 180px;
				.img {
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
				}
			}
			.actionSection {
				flex-direction: column;
				height: unset;
				padding: 0 15px;
				& > * {
					margin-bottom: 15px;
					margin-left: 0;
				}
				.actionBtn {
					width: 100%;
					height: 48px;
				}
			}
			.otherPlaceMeals {
				padding: 0 15px;
			}
			.mobileViewTabs {
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				margin-top: 30px;
				.viewTabBtn {
					font-size: 17px;
					color: var(--gunmetal-color);
					padding: 5px 23px;
					min-width: 150px;
				}
				.selectedTab {
					color: black;
					border-bottom: 3px solid var(--main-color);
					font-weight: 600;
				}
			}
			.placeSection {
				padding: 0 20px;
				margin-bottom: 80px;
			}
		}
	}
}
