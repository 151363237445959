@use './src/mediaStyles';

.root {
	position: absolute;
	top: 15px;
	z-index: 3px;
	left: 50%;
	transform: translateX(-45%);
	background: var(--background-color);
	padding: 10px;
	border-radius: 3px;
	box-shadow: 2px 1px 13px 0 rgba(0, 0, 0, 0.2);
	.searchInput {
		background: none;
		border: none;
		font-family: Assistant, sans-serif;
		color: #7c818b;
		direction: rtl;
		width: 240px;
		font-size: 14px;
		&::placeholder {
			color: '#ccd3e1';
			opacity: 0.7;
		}
		&:focus {
			border-color: var(--main-color);
			outline: none;
		}
	}
	.searchIcon {
		position: absolute;
		font-size: 12px;
		left: 15px;
		top: 35%;
		color: black;
	}
	.clearIcon {
		position: absolute;
		font-size: 14px;
		left: 6%;
		opacity: 0.7;
		top: 35%;
		&:hover {
			cursor: pointer;
		}
	}
	.focusButton {
		position: absolute;
		top: 0;
		left: -38px;
		padding: 10px;
		border-radius: 3px 0 0 3px;
		background: var(--background-color);
		border-right: solid 1px #ccd3e1;
		&:hover {
			background: #ebe8e1;
		}
	}
}

@media (max-width: mediaStyles.$breakpoint-mobile) {
	.root {
		position: relative;
		background: white;
		padding: 15px 0;
		left: 0;
		transform: translateX(0);
		top: 0;
		width: 100%;
		box-shadow: 2px 1px 13px 0 rgba(0, 0, 0, 0.2);
		z-index: 3;
		.searchInput {
			color: black;
			font-size: 16px;
			width: 90%;
			&::placeholder {
				color: black;
			}
		}
		.clearIcon {
			left: 70px;
		}
		.focusButton {
			left: 0;
			padding: 15px;
			background: white;
		}
	}
}
