@use './src/mediaStyles';

.loginContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	direction: ltr;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 420px;
	width: 650px;
	position: relative;

	.loginTitle {
		font-size: 26px;
		font-weight: 600;
		position: absolute;
		top: 110px;
		right: 30px;
	}
	.loginBlock {
		color: rgba(255, 60, 0, 0.719);
		position: absolute;
		top: 175px;
		right: 30px;
		text-align: right;
		direction: rtl;
		font-size: 14px;
	}
	.facebookButton {
		position: absolute;
		top: 220px;
		right: 44px;
	}
	.googleButton {
		position: absolute;
		top: 280px;
		left: 44px;
	}
	.loginError {
		position: absolute;
		bottom: 10px;
		left: 20px;
		font-size: 16px;
		direction: rtl;
	}
	.termsLink {
		position: absolute;
		bottom: -8px;
		right: 10px;
		width: 180px;
		text-align: right;
		font-size: 12px;
	}
}

@media (max-width: mediaStyles.$breakpoint-mobile) {
	.loginContainer {
		width: 100%;
		height: calc(100vh - 100px);
		background-size: cover;
		background-position: bottom;
		.loginTitle {
			position: relative;
			top: unset;
			right: unset;
			margin-bottom: 7px;
		}
		.loginBlock {
			position: relative;
			top: unset;
			right: unset;
			text-align: center;
			margin-bottom: 40px;
			max-width: 80vw;
		}
		.facebookButton {
			position: relative;
			top: unset;
			right: unset;
			margin-bottom: 18px;
		}
		.googleButton {
			position: relative;
			top: unset;
			left: unset;
		}
		.termsLink {
			right: 5px;
			bottom: -8px;
		}
	}
	.webViewWarn {
		position: absolute;
		bottom: 120px;
		font-size: 13px;
		width: 80%;
		left: 50%;
		transform: translateX(-50%);
		.questLink {
			font-weight: bold;
		}
	}
}
