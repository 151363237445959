@use './src/colors';

.root {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 5px 0;
	border-width: 2;
	border-radius: 2;
	border-color: #b1b1b1;
	border-style: dashed;
	background-color: #fafafa;
	max-width: calc(100% - 6px);
	color: black;
	outline: none;
	transition: border 0.2s ease-in-out;
	position: relative;
	&:hover {
		cursor: pointer;
	}
	.filesWrapper {
		padding: 15px 0;
		width: 100%;
		text-align: center;
		.previewWrapper {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
			flex-wrap: wrap;
			margin: 0;
			text-align: left;
			.previewItem {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				position: relative;
				.delBtn {
					position: absolute;
					top: -5px;
					right: 5px;
					width: 23px;
					height: 23px;
					background-color: colors.$error-color;
					border-radius: 50%;
					font-size: 16px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-weight: bold;
					box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 4px;
					color: white;
					&:hover {
						cursor: pointer;
					}
				}
				.previewImage {
					width: 80px;
					height: 80px;
					border-radius: 3px;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
				}
				.previewPath {
					width: 100px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					direction: ltr;
					text-align: center;
					font-size: 13px;
				}
			}
		}
	}
}
