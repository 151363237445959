.root {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 5px;
}
.labelWrapper {
	transition: all 150ms;
	margin-right: 6px;
	font-size: 14px;
	&:hover {
		cursor: pointer;
	}
	.label {
		text-overflow: ellipsis;
	}
}
.limitTextLength {
	max-width: 80%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.styledCheckBox {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid black;
	margin-top: 1.5px;
	margin-left: -2px;
	width: 10px;
	height: 10px;
	border-radius: 2px;
	transition: all 100ms;
	&:hover {
		cursor: pointer;
	}
}
.hiddenCheckBox {
	border: 0;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}
.icon {
	fill: none;
	margin: auto;
	transition: all 150ms;
}
