@use './src/mediaStyles';

.root {
	position: relative;
	height: 100%;
	width: 100%;
	max-width: 800px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	.contentWrapper {
		width: 80%;
		margin: auto;
	}
	.title {
		font-size: 39px;
		letter-spacing: 0;
		font-weight: bold;
		margin: 0;
		margin-bottom: 20px;
	}
	.pTextTitle {
		font-size: 19px;
		font-weight: 600;
	}
	.pText {
		margin-top: 0;
		font-size: 19px;
		color: var(--gunmetal-color);
		line-height: 1.28;
		margin-bottom: 30px;
	}
	.secondTitle {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 8px;
		line-height: 1.4;
		color: var(--gunmetal-color);
	}
	.btnsWrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
		justify-content: space-evenly;
		position: relative;
		& > * {
			margin-left: 5px;
		}
		.geoBtn {
			padding: 10px 20px;
			text-align: center;
			border-radius: 3px;
			border: solid 1px #ccd3e1;
			transition: all 0.3s ease-out;
			margin-bottom: 8px;
			.radio {
				&:first-child {
					display: none;
				}
			}
			& > * {
				font-size: 16px;
				font-weight: bold;
			}
		}
		.selectedBtn {
			background: black;
			color: white;
		}
	}
	.submitBtn {
		margin: 20px auto;
		font-size: 16px;
		font-weight: bold;
		box-shadow: rgba(255, 187, 0, 0.5);
	}
}

@media (max-width: mediaStyles.$breakpoint-mobile) {
	.root {
		.contentWrapper {
			width: 99%;
		}
		.title {
			font-size: 27px;
		}
		.pTextTitle {
			font-size: 18px;
		}
		.pText {
			font-size: 17px;
			margin-bottom: 10px;
		}
	}
}
