.root {
	border-bottom: 1px solid var(--secondary-color);
	padding: 5px 0;
}
.title {
	font-weight: 600;
	margin-left: 5px;
	text-align: right;
}
.details {
	text-align: right;
}
.rating {
	display: flex;
	justify-content: left;
	align-items: center;
	font-weight: 600;
	text-align: left;
	margin-right: -5px;
	font-size: 14px;
}
.ratingStar {
	font-size: 13px;
	margin-right: 8px;
}
